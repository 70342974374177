import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactButton from './components/ContactButton';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Home from './components/Home';
import NotFound from './components/NotFound';
import './App.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <ContactButton />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
