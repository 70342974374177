import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import logoFooter from '../assets/logo_footer.png';
import './Footer.css';

const Footer = () => {
    return (
        <Box component="footer" className="footer-container">
            <Box className="footer-content">
                <Link to="/">
                    <img src={logoFooter} alt="Logo" className="footer-logo" />
                </Link>
                <Box>
                    <Typography variant="body1">
                        <a href="/contact" className="footer-link gabarito">CONTACT US</a>
                    </Typography>
                </Box>
                <Box>
                    <IconButton href="https://www.linkedin.com" target="_blank">
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton href="https://www.instagram.com" target="_blank">
                        <InstagramIcon />
                    </IconButton>
                </Box>
            </Box>
            <Typography variant="body2" color="textSecondary">
                Copyright © 2024 Cygnus Logistic
            </Typography>
        </Box>
    );
};

export default Footer;
