import React from 'react';
import { Tooltip, Fab } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';

const ContactButton = () => {
  return (
    <div className="contact-button">
      <Tooltip title="CONTACT US" arrow>
        <Fab color="primary" aria-label="contact">
          <MailIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default ContactButton;
