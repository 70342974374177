import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import './Navbar.css';
import logo from '../assets/logo.png';

const pages = [
  { name: 'About Us', path: '/about' },
  { name: 'Products', path: '/products' },
  { name: 'Contact Us', path: '/contact' }
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <AppBar position="static" className="navbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box className="navbar-logo-container" onClick={handleLogoClick}>
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Box>
          <Box className="navbar-links">
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                className="navbar-button"
                sx={{ color: 'white', fontFamily: 'Gabarito', fontSize: '18px', margin: '0 10px' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <Box className="navbar-menu-button">
            <IconButton
              size="large"
              aria-label="menu"
              aria-haspopup="true"
              onClick={handleToggleMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box className={`navbar-dropdown-menu ${menuOpen ? 'open' : ''}`}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                onClick={handleToggleMenu}
                className="navbar-dropdown-item"
                sx={{ color: 'white', fontFamily: 'Gabarito', fontSize: '18px' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
