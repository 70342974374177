import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#37779b',
    },
    text: {
      primary: '#ffffff',
    },
  },
});


export default theme;
