import React from 'react';
import './FullWidthSection.css';

const FullWidthSection = ({ backgroundColor, backgroundImage, textColor, children }) => {
  const sectionStyle = {
    backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    color: textColor,
  };

  return (
    <div className="full-width-section" style={sectionStyle}>
      <div className="content-container">
        {children}
      </div>
    </div>
  );
};

export default FullWidthSection;
