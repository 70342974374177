import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './HeroCarousel.css';
import carousel1 from '../assets/carousel_1.jpeg';
import carousel2 from '../assets/carousel_2.jpg';
import carousel3 from '../assets/carousel_3.jpeg';

const HeroCarousel = () => {
  return (
    <Carousel
      showArrows
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      autoPlay
      interval={5000}
    >
      <div>
        <img src={carousel1} alt="First Slide" />
        <div className="legend">
          <h1>WELCOME TO OUR SITE</h1>
          <p>Description for the first slide.</p>
        </div>
      </div>
      <div>
        <img src={carousel2} alt="Second Slide" />
        <div className="legend">
          <h1>ANOTHER HEADLINE</h1>
          <p>Description for the second slide.</p>
        </div>
      </div>
      <div>
        <img src={carousel3} alt="Third Slide" />
        <div className="legend">
          <h1>YET ANOTHER HEADLINE</h1>
          <p>Description for the third slide.</p>
        </div>
      </div>
    </Carousel>
  );
};

export default HeroCarousel;
