import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledButton = styled(Button)(({ buttoncolor }) => ({
  borderRadius: '50px',
  border: `2px solid ${buttoncolor}`,
  color: buttoncolor,
  padding: '10px 20px',
  fontFamily: 'Gabarito, sans-serif',  
  fontSize: '1.25rem',
  textTransform: 'uppercase',
  transition: 'background-color 0.3s ease, border 0.3s ease', // Añadir transición para suavizar el cambio
  '&:hover': {
    backgroundColor: `rgba(${parseInt(buttoncolor.slice(1, 3), 16)}, ${parseInt(buttoncolor.slice(3, 5), 16)}, ${parseInt(buttoncolor.slice(5, 7), 16)}, 0.1)`, // Ajusta el color del fondo en hover
    border: `2px solid ${buttoncolor}`, // Mantén el grosor del borde en hover
  },
}));

const RoundedButton = ({ children, buttoncolor, to }) => {
  return to ? (
    <Link to={to} className="link">
      <StyledButton buttoncolor={buttoncolor} variant="outlined">
        {children}
      </StyledButton>
    </Link>
  ) : (
    <StyledButton buttoncolor={buttoncolor} variant="outlined">
      {children}
    </StyledButton>
  );
};
export default RoundedButton;
