import React from 'react';
import FullWidthSection from './FullWidthSection';
import RoundedButton from './RoundedButton';
import HeroCarousel from './HeroCarousel';
import './Home.css';

const Home = () => (
    <main className="home-container">
        <section className="hero-section">
            <HeroCarousel />
        </section>

        <FullWidthSection backgroundColor="#ffffff" textColor="#303030">
            <h2>ABOUT US</h2>
            <p>Description.</p>
            <RoundedButton buttoncolor="#303030" to="/about">LEARN MORE</RoundedButton>
        </FullWidthSection>

        <FullWidthSection backgroundColor="#2f8a58" textColor="#ffffff">
            <h2>OUR PRODUCTS</h2>
            <p>Description.</p>
            <RoundedButton buttoncolor="#ffffff" to="/products">EXPLORE PRODUCTS</RoundedButton>
        </FullWidthSection>

        <FullWidthSection backgroundColor="#b2d0d9" textColor="#303030">
            <h2>CONTACT US</h2>
            <p>Get in touch with us for more information.</p>
            <RoundedButton buttoncolor="#303030" to="/contact">CONTACT NOW</RoundedButton>
        </FullWidthSection>
    </main>
);

export default Home;
